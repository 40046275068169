var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "自定义表单模板",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-template": _vm.goAddTemplate },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.option,
          "table-data": _vm.tableData,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.goDetails(row)
                      },
                    },
                  },
                  [_vm._v("查看详情\n      ")]
                ),
                row.busiCode !== "old"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          size: "small",
                          disabled: row.paModelStatus === "1",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goEdit(row)
                          },
                        },
                      },
                      [_vm._v("模板修订\n      ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }